var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"body"},[_c('h2',{staticClass:"title"},[_vm._v("Categorie")]),_c('h3',{attrs:{"id":"ajout"}},[_vm._v("Ajouter un Categorie")]),_c('h4',[_vm._v(" Si vous souhaitez ajouter une nouvelle catégorie, veuillez suivre les étapes ci-dessous : ")]),_vm._m(0),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          `public/how-it-works/videos/1682597551_AjouterUneCatégorie.mp4`,"controls":""}})]),_c('h3',{attrs:{"id":"edit"}},[_vm._v("Modifier une Categorie")]),_c('h4',[_vm._v(" Si vous souhaitez modifier une catégorie, veuillez suivre les étapes ci-dessous : ")]),_vm._m(1),_c('h3',{attrs:{"id":"delete"}},[_vm._v("Supprimer une Categorie")]),_c('h4',[_vm._v(" Si vous souhaitez supprimer une catégorie, veuillez suivre les étapes ci-dessous : ")]),_vm._m(2),_c('p',[_vm._v(" N'oubliez pas que cette action est irréversible et que toutes les informations associées au type de pièce seront également supprimées. ")]),_c('h3',{attrs:{"id":"recherche"}},[_vm._v("Rechercher ou filtrer les categorie")]),_c('h4',[_vm._v("Recherche categorie :")]),_vm._m(3),_c('h4',[_vm._v("Filtrage des categorie :")]),_vm._m(4),_c('div',{staticClass:"bulle"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"33","height":"33","viewBox":"0 0 33 33"}},[_c('g',{attrs:{"id":"Icon_feather-info","data-name":"Icon feather-info","transform":"translate(-1.5 -1.5)"}},[_c('path',{attrs:{"id":"Tracé_78064","data-name":"Tracé 78064","d":"M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}}),_c('path',{attrs:{"id":"Tracé_78065","data-name":"Tracé 78065","d":"M18,24V18","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}}),_c('path',{attrs:{"id":"Tracé_78066","data-name":"Tracé 78066","d":"M18,12h0","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}})])]),_vm._v(" Nous espérons que ces instructions vous ont été utiles. Si vous avez des questions ou des problèmes, n'hésitez pas à nous contacter via notre email de contact. Merci de nous faire confiance et à bientôt sur Batigo ! ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Allez dans la page de gestion des catégories.")]),_c('li',[_vm._v("Cliquez sur le bouton \"Nouveau\".")]),_c('li',[_vm._v(" Remplissez le formulaire d'ajout (* il y a des champs obligatoires). ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Enregistrer\". Votre nouvelle catégorie est ajoutée avec succès. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v(" Accédez à la page de gestion des catégories en cliquant sur le bouton \"Catégories\". ")]),_c('li',[_vm._v(" Trouvez la catégorie que vous souhaitez modifier dans la liste des catégories ")]),_c('li',[_vm._v("Cliquez sur le bouton \"Modifier\".")]),_c('li',[_vm._v(" Modifiez le champs catégorie que vous souhaitez (* il y a des champs obligatoires). ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Enregistrer\" pour enregistrer les modifications apportées à la catégorie. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Accédez à la page de gestion des catégories.")]),_c('li',[_vm._v(" Trouvez la catégorie que vous souhaitez supprimer dans la liste. ")]),_c('li',[_vm._v("Cliquez sur le bouton \"Supprimer\" à côté de cette catégorie.")]),_c('li',[_vm._v(" Confirmez la suppression : une fenêtre de confirmation apparaîtra pour vous demander de confirmer la suppression. "),_c('br'),_vm._v(" * Si vous êtes sûr de vouloir supprimer cette catégorie, cliquez sur \"Supprimer\". ")]),_c('li',[_vm._v("La catégorie sera supprimée de la liste.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Allez sur la page de gestion des catégories.")]),_c('li',[_vm._v(" Entrez le nom de la catégorie que vous souhaitez rechercher dans la barre de recherche. ")]),_c('li',[_vm._v("Cliquez sur le bouton de recherche.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Allez sur la page de gestion des catégories.")]),_c('li',[_vm._v(" Utilisez l'option de filtre pour afficher les catégories publiques ou masquées. ")]),_c('li',[_vm._v("Cliquez sur le bouton de filtrage.")])])
}]

export { render, staticRenderFns }